<!--
 * @Description: 问卷编辑主页
 * @version: 
 * @Author: PSG
 * @Date: 2021-05-17 15:20:41
 * @LastEditors: PSG
 * @LastEditTime: 2021-05-19 18:07:30
-->
<template>
<div class="edit">
  <router-view></router-view>
</div>
</template>

<script>
import globalQuestionnaire from '@/class/useQuestionnaire'
import { onUnmounted } from 'vue'
export default {
  name: 'Edit',
  setup() {
    onUnmounted(() => {
      globalQuestionnaire.clearQuestionnaire()
    })
  }
}
</script>

<style lang="less" scoped>
.edit {
  width: 100vw;
  height: 100vh;
  background-color: @backgroundColor;
}
</style>